.ant-badge.badge-container {
  width: 100%;
}

.badge {
  color: var(--secondary-color);
  font-size: 20px;
}

.card-container {
  border: 1px solid black;
  border-radius: 0 10px 10px 0;
  flex: 1;
  display: flex;
  /* gap: 4px; */
  height: 100%;
  min-height: 50px;
  cursor: grab;
}

.contact-info-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 5px;
  padding: 0 5px;
}

.name-container {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bar-chart-container > .bar-chart-wrapper > .bar-container {
  border-width: 0 1px 0 0;
}

@media (min-width: 768px) and (max-width: 1400px) {
  .bar-chart-container > .bar-chart-wrapper > .bar-container {
    border-width: 1px 0 0 0;
  }

  .bar-chart-container > .bar-chart-wrapper > .bar-container:not(:first-child) {
    border-top: 1px solid black;
    border-left: none;
  }

  .card-container {
    border-radius: 20px 0 0 0;
    flex-direction: column-reverse;
    margin: 5px 0;
    min-height: fit-content;
  }

  .contact-info-container {
    margin: 5px;
    padding: 0;
  }

  .contact-info-name-container {
    margin: 5px 5px 5px 8px;
    padding: 0;
  }
}
