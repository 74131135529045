.impact-row {
  padding: 10px 5px;
  margin-bottom: 10px;
  border: 1px solid gray;
}

.impact-container {
  margin: 0 5px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 5px;
}

.impact-name {
  color: black;
  margin-left: 10px;
}
