.crud-container-width {
  width: fit-content;
  min-width: 35vw;
}

.hide-heading > div > .ant-page-header-heading {
  display: none;
}

.crud-container-no-padding {
  padding: 0 !important;
}

@media (max-width: 1000px) {
  .crud-container-width {
    min-width: 60vw;
  }
}

@media (max-width: 500px) {
  .crud-container-width {
    min-width: none;
    width: 100%;
  }
}
