.relationship-group {
  min-height: 80px;
  height: 100%;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: gray;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.relationship-card {
  display: flex;
  width: 85%;
  margin: 10px 0;
}

.relationship-card:first-child {
  margin-top: 20px;
}

.relationship-card:last-child {
  margin-bottom: 20px;
}

.relationship-row:first-child {
  border-left: 1px solid gray;
}

@media (max-width: 767px) {
  .relationship-row:first-child {
    border-left: none;
  }

  .relationship-row:last-child {
    border-bottom: 1px solid gray;
  }

  .relationship-group {
    border-width: 0 1px 0 1px;
  }
}
