.bar-element {
  flex: 1;
  min-height: 10px;
  border-top: 1px solid black;
  width: 10px;
}

.bar-element:last-child {
  border-top: none;
}

.bar-container {
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid black;
  align-items: stretch;
  width: fit-content;
}
