.contact-type-badge-container {
  border-radius: 50%;
  padding: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relationship-strength {
  padding: 5px;
  color: white;
}

.contact-type-icon {
  color: black;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
