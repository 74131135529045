@import "@pankod/refine-antd/dist/styles.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto");

:root {
  --primary-color: #993399;
  --primary-color-rgb: rgb(153, 51, 153);
  --primary-color-hover: #ac5aac;
  --secondary-color: #aa1e2f;
  --secondary-color-hover: #df8a93;
  --box-shadow: 0 0 0 2px rgba(153, 51, 153 0.2);
  --influencer: #f4c39b;
  --implementer: #b098c3;
  --introducer: #f0efa2;
  --information: #83cdf0;
  --error: #fa541c;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
}

.bold {
  font-weight: bold;
}

.influencer-background {
  background-color: var(--influencer) !important;
}

.implementer-background {
  background-color: var(--implementer) !important;
}

.introducer-background {
  background-color: var(--introducer) !important;
}

.information-background {
  background-color: var(--information) !important;
}

.primary {
  color: var(--primary-color) !important;
}

.secondary {
  color: var(--secondary-color) !important;
}

a.link {
  font-weight: bold;
  color: var(--primary-color);
}

.ant-page-header-heading-title {
  font-size: 30px;
  padding: 10px 0;
  color: var(--secondary-color);
  font-family: "Roboto";
}

::selection {
  color: #fff;
  background: var(--primary-color);
}

.ant-btn:hover,
.ant-btn:focus {
  color: #993399;
  border-color: #993399;
  background: #fff;
}

.ant-btn-primary {
  color: #fff;
  border-color: var(--primary-color);
  background: var(--primary-color);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: var(--primary-color-hover);
  background: var(--primary-color-hover);
}

.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7a45;
  border-color: #ff7a45;
  background: #fff;
}

.ant-layout-sider {
  background: var(--primary-color);
}

.ant-layout-sider-trigger {
  background: rgba(0, 0, 0, 0.2);
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: var(--primary-color);
}

.ant-pagination-item-active a {
  color: var(--primary-color);
}

.ant-pagination-item:hover {
  border-color: var(--primary-color-hover);
  transition: all 0.3s;
}

.ant-pagination-item-active:hover {
  border-color: var(--primary-color-hover);
}

.ant-pagination-item:hover a {
  color: var(--primary-color-hover);
}

.ant-pagination-item:focus-visible {
  border-color: var(--primary-color-hover);
}

.ant-pagination-item-active:focus-visible {
  border-color: var(--primary-color-hover);
}

.ant-pagination-item-active:hover a {
  color: var(--primary-color-hover);
}

.ant-pagination-item-link-icon:hover {
  color: var(--primary-color-hover);
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--primary-color-hover);
  border-color: var(--primary-color-hover);
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: var(--primary-color);
}

.ant-input:hover {
  border-color: var(--primary-color);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--primary-color);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: var(--primary-color);
  box-shadow: var(--box-shadow);
}

.ant-input:focus,
.ant-input-focused {
  border-color: var(--primary-color);
  box-shadow: var(--box-shadow);
}

.ant-typography a {
  color: var(--primary-color);
}
a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: var(--primary-color-hover);
}

/* ant picker  */

.ant-picker-input > input:hover {
  border-color: var(--primary-color);
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: var(--primary-color);
}

.ant-picker-header-view button:hover {
  color: var(--primary-color);
}

.ant-picker-focused {
  border-color: var(--primary-color);
  box-shadow: var(--box-shadow);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--primary-color);
}

.ant-picker-today-btn,
.ant-picker-today-btn:active,
.ant-picker-today-btn:hover {
  color: var(--primary-color);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--primary-color);
}

/* ant design table*/
.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: var(--primary-color);
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--primary-color);
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #eeaeee;
  border-color: rgba(0, 0, 0, 0.03);
}

.ant-table-tbody > tr.ant-table-row-selected > td > .ant-typography {
  color: black;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #f0bff0;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ant-checkbox-checked::after {
  border: 1px solid var(--primary-color);
}

/* contacts table  */

.table-extra {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
}

/* Sing in / registration  */

.sign-in-background {
  background: radial-gradient(
    50% 50% at 50% 50%,
    var(--primary-color) 0%,
    #6b046b 100%
  );
  background-size: "cover";
}

.sign-in-form {
  max-width: 408px;
  margin: 20px auto;
}

.sign-in-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.sign-in-logo {
  max-width: 300px;
}

.sign-in-form-title {
  text-align: center;
  color: #626262 !important;
  font-size: 30px !important;
  letter-spacing: -0.04em;
  white-space: normal;
}

/* Slider  */
.ant-slider-track {
  background-color: var(--primary-color);
}

.ant-slider-handle {
  border-color: var(--primary-color-hover);
}

.ant-slider-dot-active {
  border-color: var(--primary-color-hover);
}

.ant-slider:hover .ant-slider-track {
  background-color: var(--primary-color-hover);
}

.ant-slider-handle:focus {
  border-color: var(--primary-color-hover);
  box-shadow: 0 0 0 5px rgb(153, 51, 153, 12%);
}

.ant-slider-handle.ant-tooltip-open {
  border-color: var(--primary-color);
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: var(--primary-color);
}

.ant-slider-mark > span:first-child {
  transform: translateX(0) !important;
}
.ant-slider-mark > span:last-child {
  transform: translateX(-100%) !important;
}

/* Radio */
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--primary-color-hover);
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--primary-color);
}

.ant-radio-inner::after {
  background-color: var(--primary-color);
}

/* Matrix */

#matrix-body > div > div > div.ant-page-header-content > div > div > div > div {
  padding: 0;
}

/* Matrix sider */

#matrix-sider > div {
  overflow: auto;
}

/* Modal */
.without-animation {
  animation-duration: 0s !important;
}

/* Buttons */
.icon-button {
  background-color: transparent !important;
  border: none;
}

.icon-button:hover {
  border: none;
}

/* Category filed */
.category-form-item.ant-form-item {
  margin-bottom: 10px;
}

/* Radio buttons */

.relationship-matrix-radio-buttons {
  display: flex;
  gap: 10px;
}

.relationship-matrix-radio-buttons > .ant-radio-button-wrapper {
  border: 1px solid #d9d9d9;
  border-top-width: 1px;
  border-left-width: 1px;
  border-radius: 20px;
}

.relationship-matrix-radio-buttons > .ant-radio-button-wrapper:hover {
  color: var(--primary-color);
}

.relationship-matrix-radio-buttons > .ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}

.relationship-matrix-radio-buttons
  > .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.relationship-matrix-radio-buttons
  > .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: var(--primary-color-hover);
  border-color: var(--primary-color-hover);
}

.relationship-matrix-radio-buttons
  > .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):first-child {
  border-color: var(--primary-color-hover);
}

.relationship-matrix-radio-buttons
  > .ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border-right-color: var(--primary-color-hover);
}

.relationship-matrix-radio-buttons
  > .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

/*  */

/* Editable typography */
/* .anticon-edit {
  color: var(--secondary-color) !important;
} */

.editable-title {
  align-items: center !important;
  margin-bottom: 0 !important;
  padding-left: 5px;
}

.typography-edit-icon > .ant-typography-edit > .anticon-edit {
  color: var(--secondary-color);
}

.editable-typography > div > span > svg {
  color: white !important;
}

.editable-typography {
  align-items: center !important;
  margin-bottom: 0 !important;
  padding-left: 5px;
}

.editable-typography.ant-typography-edit-content {
  left: 0;
  padding-left: 0px;
  margin: 0;
}

/* Spinner */

.ant-spin-dot-item {
  background-color: var(--primary-color) !important;
}

/*  */
.single-row-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* Contacts table*/
#contacts-list-table > div > div.ant-page-header-heading {
  align-items: center;
}

#contacts-table
  > div
  > div
  > table
  > tbody
  > tr
  > td.ant-table-cell.ant-table-selection-column {
  padding: 8px;
}

.contacts-list-cell {
  padding: 8px !important;
}

/* Action plans */

.action-panel > .ant-collapse-header {
  align-items: center !important;
}

/* Slide animation */
.slide-in-right {
  -webkit-animation: slide-in-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* Cookie consent */

.cookie-consent-container {
  background-color: white !important;
  color: black !important;
  display: block !important;
  width: 20vw !important;
  left: 20px !important;
  bottom: unset;
  /* hack */
  margin-bottom: 20px;
  border-radius: 5px;
  -webkit-box-shadow: -7px 0px 42px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -7px 0px 42px -8px rgba(0, 0, 0, 0.75);
  box-shadow: -7px 0px 42px -8px rgba(0, 0, 0, 0.75);
}

.cookie-consent-accept-button {
  width: calc(100% - 30px);
  border: 2px solid var(--primary-color) !important;
  background-color: white !important;
  color: var(--primary-color) !important;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px !important;
}

.cookie-consent-accept-button:hover {
  width: calc(100% - 35px);
  background-color: var(--primary-color) !important;
  color: white !important;
}

/* Hr text */

.hr-with-text {
  display: flex;
  color: var(--secondary-color);
  padding-bottom: 15px;
}

.hr-with-text::before {
  content: "";
  flex: 1;
}

.hr-with-text::after {
  content: "";
  flex: 1;
}

.hr-line {
  align-items: center;
  margin: 1em -1em;
}

.hr-line::before {
  height: 1px;
  margin: 0 1em;
}
.hr-line::after {
  height: 1px;
  margin: 0 1em;
}

.hr-one-line::before {
  background: var(--secondary-color);
}
.hr-one-line::after {
  background: var(--secondary-color);
}
/* Table body */

.agreements-table-body > tr > * {
  vertical-align: top;
}

/* File drop zone */
.file-drop-zone-error {
  border-color: var(--error) !important;
}

/* Contacts drawer buttons */
.contacts-drawer-buttons {
  margin-bottom: 10px;
  float: right;
}

.default-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media screen and (max-width: 776px) {
  .cookie-consent-container {
    width: 40vw !important;
  }
}

@media screen and (max-width: 500px) {
  .ant-radio-group {
    flex-direction: column;
  }
  .ant-radio-button-wrapper {
    width: 100%;
    /* margin: 10px 0; */
  }
  .cookie-consent-container {
    width: 60vw !important;
  }

  .hr-line {
    align-items: unset;
    margin: unset;
  }

  .hr-line::before {
    height: unset;
    margin: unset;
  }
  .hr-line::after {
    height: unset;
    margin: unset;
  }

  .hr-one-line::before {
    background: unset;
  }
  .hr-one-line::after {
    background: unset;
  }

  .contacts-drawer-buttons {
    flex-direction: column;
    align-items: stretch !important;
  }
}

@media (max-width: 417px) {
  .table-extra {
    margin-left: 0;
  }
}

@media (max-width: 374px) {
  .sign-in-logo {
    max-width: 200px;
  }
  .sign-in-form-title {
    font-size: 20px !important;
  }
  .ant-radio-group {
    display: block;
  }
  .ant-radio-button-wrapper {
    margin: 10px 0;
  }
}

@media (max-width: 318px) {
  #contacts-list-table > div > div.ant-page-header-heading > span {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}
