.bar-chart-wrapper {
  display: flex;
  align-items: stretch;
}

.bar-chart-wrapper > .bar-container:not(:first-child) {
  border-left: none;
}

@media (min-width: 768px) and (max-width: 1400px) {
  .bar-chart-wrapper > .bar-container > .bar-element {
    min-height: none;
    min-width: none;
    width: 100%;
    height: 10px;
    border-right: 1px solid black;
    border-top: none;
  }

  .bar-chart-wrapper > .bar-container >  .bar-element:last-child {
    border-right: none;
  }

  .bar-chart-wrapper > .bar-container {
    flex-direction: row;
    border: 1px solid black;
    width: 100%;
  }

  .bar-chart-wrapper > .bar-container:not(:first-child) {
    border-left: 1px solid black;
  }

  .bar-chart-wrapper > .bar-container:not(:first-child) {
    border-top: none;
  }

  .bar-chart-wrapper {
    flex-direction: column;
  }
}
