.relationship-container {
  width: fit-content;
  border-radius: 20%;
}

.relationship {
  color: white !important;
  padding: 20px 10px;
  font-weight: bold;
}
