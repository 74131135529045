.action-plan-button {
  /* margin-bottom: 24px; */
  display: flex;
  align-items: center;
  gap: 5px;
}

.action-plan-button-icon {
  font-size: 30px !important;
  color: var(--secondary-color) !important;
}

.action-plan-button-icon-disabled {
  font-size: 30px !important;
  color: gray !important;
}

.action-plan-button-text {
  font-size: 14px;
  font-weight: 600;
  color: #626262;
}
