.matrix-card {
  border: 1px solid gray;
  border-radius: 20px;
  padding: 0 20px;
  height: 200px;
}

.matrix-card-body {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 70%;
  overflow: hidden;
}

.matrix-card-actions {
  overflow: hidden;
  border-top: 1px solid gray;
  height: 30%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
}

.matrix-image {
  width: 100px;
  height: 100px;
}

.matrix-name {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.matrix-create-card-body {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: rgb(133, 133, 133, 0.2);
  cursor: pointer;
}

.matrix-create-title-container {
  flex: 1;
  margin-top: 50px;
}

.matrix-create-title {
  color: #858585 !important;
  user-select: none;
}

.matrix-edit-btn {
  color: var(--secondary-color) !important;
}
